<template>
  <div class="content">
    <div class="content-top">
      <!-- 面包屑 -->
      <div class="breadcrumbs">
        <i></i>
        <a href="/">首页</a><span>></span>
        <a href="#" class="active">项目展示</a>
      </div>
      <!-- 侧边栏 + 头部内容 -->
      <div class="top-sidebar">
        <div class="fl fw">
          <div class="one">
            <img class="one_img" src="/static/img/icon_title.png" alt="" />
            <span class="one_span">{{ this.pagetitle }}</span>
          </div>
          <div class="h_div"></div>
          <div class="two" style="padding-top: 10px; padding-bottom: 10px">
            <div class="box" v-for="(item,index) in list" :key="index">
              <div class="imgdiv">
                <a @click="handleDetail(item)"><img :src='item.image_url'/></a>
              </div>
              <p>
                <a @click="handleDetail(item)">{{ item.title.length > 12 ? item.title.slice(0,12) + '...' : item.title }}</a>
              </p>
            </div>


            <div style="clear: both"></div>
          </div>
          <div class="pages">
            <a class="prev_btn" v-if="isfirst" @click="handlePage(curpage-1)">上一页</a>
            <a class="num" v-for="(pindex, index) in pagelist" :key="index" v-bind:class="{act:curpage == pindex}" @click="handlePage(pindex)">{{ pindex }}</a>
            <!-- <a class="num" v-bind:class="{act:curpage == totalpage}" @click="handlePage(totalpage)">{{ totalpage }}</a> -->
            <a class="next_btn" v-if="islatest" @click="handlePage(curpage+1)">下一页</a>

          </div>
        </div>
        <div class="right sidebar">
          <ul>
            <li>
              <div class="sidebar-title active">
                <span>项目展示</span>
              </div>
              <!-- 子菜单 -->
              <ul class="sidebar-health">
                <li  :class="this.cid == 4 ? 'health-active' : ''" @click="gotoAbout('/cases/4')">机构养老</li>
                <li  :class="this.cid == 7 ? 'health-active' : ''" @click="gotoAbout('/cases/7')">日间照料中心</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div style="clear: both"></div>
</template>

<style src="../../../public/static/css/claim-download.css" />
<style src="../../../public/static/css/news.css" />
<style>
.box {
  width: 200px;
  float: left;
  margin: 20px 10px;
  border: 1px solid #ccc;
}
.box img {
  width: 200px;
  height: 200px;
}
.box p {
  font-size: 14px;
  padding: 10px 5px;
  background-color: #f6bf7d;
}
.box .imgdiv {
  background-color: #001259;
}
.box a {
  color: #000;
}
.box a:hover {
  text-decoration: underline;
  cursor:pointer;
}
.fw{width:calc(100% - 207px);}
</style>

<script>
import * as Api from '@/api'
export default {
  data() {
    return {
      cid: null,
      curpage:1,
      totalpage:0,
      perpage:0,
      totalcount:0,
      list:[],
      isfirst: false,
      islatest: false,
      morethan5:false,
      pagelist:[]
    }
  },
  created(){
    this.cid = this.$route.params.cid;
    this.loadData(1)
    if(this.cid == 4){
      this.pagetitle = '机构养老';
    }else if(this.cid == 7){
      this.pagetitle = '日间照料中心';
    }
  },
  methods: {
    loadData(topage){
      Api.cases({page:topage, cid:this.cid}).then(response => {
        //return response.data.list
        var res = response.data.data.list
        console.log(res)
        this.curpage = res.current_page
        this.totalcount = res.total
        this.perpage = res.per_page
        this.totalpage = res.last_page
        this.list = res.data
        this.isfirst = this.curpage > 1
        this.islatest = this.curpage < this.totalpage
        let start = this.curpage - 2 <= 0 ? 1 : this.curpage - 2
        let end = this.curpage + 2 >= this.totalpage ? this.totalpage : this.curpage + 2
        
        this.pagelist = []
        for(let i = start; i <= end; i++) {
          this.pagelist.push(i)
        }
    })
   },
   handleDetail(item){
    this.$router.push({name: 'CasesDetail', params:{id: item.article_id}})
   },
   handlePage(topage){
    this.loadData(topage)
   }
  },
};
</script>
